<template>
  <div
    :class="[
      'wayfinder-layout-contrast--wrapper', {
        'wayfinder-layout-contrast--wrapper-light-theme': isLightMode,
        'wayfinder-layout-contrast--wrapper-custom-map-layout': currentLayout === 'custom',
        'wayfinder-layout-contrast--wrapper_with-text-scale': $accessibility.enableTextScale === true,
        'wayfinder-layout-contrast--wrapper_with-theme-mode': $accessibility.enableModeToggle === true

      }]"
  >
    <div
      ref="contrastWrapper"
      :class="[
        'wayfinder-layout-contrast', 'wayfinder-layout--content',
        `wayfinder-layout-contrast--sidebar_keyboard-${ keyboardPosition }`,
        {
          'wayfinder-layout-contrast--light-theme': isLightMode,
          'wayfinder-layout-contrast--sidebar_opened': sidebarOpened,
          'wayfinder-layout-contrast--sidebar_keyboard-shown': keyboardOpened
        }
      ]"
    >
      <div ref="header" class="wayfinder-layout-contrast--header">
        <div v-if="logoImage" class="wayfinder-layout-contrast--logo">
          <div
            class="wayfinder-layout-contrast--logo-image"
            :style="{ backgroundImage: `url(${ logoImage })` }"
          >
          </div>
        </div>
        <div :class="['wayfinder-layout-contrast--text', {
          'wayfinder-layout-contrast--text_big': !logoImage
        }]">
          <h1 :class="['wayfinder-layout-contrast--title', {
            'wayfinder-layout-contrast--title_big': !logoImage
          }]">
            {{ this.$withLocale(this.view.title) }}
          </h1>

          <h5 v-if="view.branch" class="wayfinder-layout-contrast--sub-title">
            {{ view.branch.name }}
          </h5>
        </div>
      </div>
      <wayfinder-button-group
        class="wayfinder-layout-contrast--header-buttons"
        :style="{ '--main-content-height': mainContentHeight }"
      >
        <wayfinder-button
          v-if="$accessibility.enableTextScale === true"
          size="big"
          class="wayfinder-layout-contrast--font-scale"
          @click.native="_changeScale()"
          :title="$l10n('text scale')"
          :aria-label-value="$l10n('text scale')"
        >
          <div class="wayfinder-layout-contrast--font-scale-indicator">
            <span class="wayfinder-layout-contrast--font-scaled">A</span>
            <span class="wayfinder-layout-contrast--font-default">a</span>
          </div>
        </wayfinder-button>
        <wayfinder-button
          v-if="$accessibility.enableModeToggle === true"
          class="wayfinder-layout-contrast--theme-toggle"
          size="big"
          @click.native="_setTheme(isLightMode ? 'contrast-dark' : 'contrast-light')"
          :aria-label-value="$l10n('toggle theme')"
          :title="$l10n('toggle theme')"
        >
          <i
            :class="[
              { 'fa-light fa-brightness': !isLightMode },
              { 'fa-solid fa-moon': isLightMode }
            ]"
          ></i>
        </wayfinder-button>
        <wayfinder-button
          :aria-label-value="$l10n(sidebarOpened ? 'menu close' : 'menu open')"
          :title="$l10n(sidebarOpened ? 'menu close' : 'menu open')"
          class="wayfinder-layout-contrast--sidebar-handle"
          corners="pill"
          size="big"
          data-focus-frame-follow="500"
          @click.native="_toggleSidebar"
        >
          <i
            :class="[
              'fa-light',
              {
                'fa-times': sidebarOpened,
                'fa-bars': !sidebarOpened
              }
            ]"
          ></i>
        </wayfinder-button>
      </wayfinder-button-group>

      <div
        ref="floatingBlock"
        class="wayfinder-layout-contrast--desktop-floating-block"
        :style="{
          '--main-content': mainContentHeight
        }">
        <div class="wayfinder-layout-contrast--scroll-tape">
          <div
            :style="mapListStyles"
            class="wayfinder-layout-contrast--maps-wrapper"
          >
            <wayfinder-map
              v-for="map in reversedMaps"
              :key="map.id"
              ref="maps"
              :map="map"
              :theme="view.theme"
              class="wayfinder-map-list--map"
              @position-changed="_updateMapDefaultPosition(map._index, $event)"
            />
          </div>
        </div>
        <div class="wayfinder-layout-contrast--map-buttons-wrapper">
          <wayfinder-select
            :value="selectedMap.id"
            :options="branchOptions"
            @change="selectMap($event)"
          />
          <wayfinder-button-group
            v-if="$accessibility.zoomDisabled !== true"
            class="wayfinder-layout-contrast--map-controls"
            gap-side="start"
          >
            <wayfinder-button
              :aria-label-value="$l10n('zoom restore')"
              :class="[
                'wayfinder-layout-contrast--map-control',
                { 'wayfinder-map-list--map-control_hidden': selectedMapInDefaultPostion }
              ]"
              :tabindex-value="selectedMapInDefaultPostion ? - 1 : 0"
              :title="$l10n('zoom restore')"
              color="black"
              modificator="only-text"
              corners="circle"
              @click.native="_resetMapPositionAndScale"
            >
              <i class="fa-light fa-arrows-rotate wayfinder-layout-contrast--control-icon"></i>
            </wayfinder-button>
            <wayfinder-button
              :aria-label-value="$l10n('zoom down')"
              :title="$l10n('zoom down')"
              class="wayfinder-layout-contrast--map-control"
              color="black"
              corners="circle"
              modificator="only-text"
              @click.native="_zoomIn"
            >
              <i class="fa-regular fa-magnifying-glass-plus wayfinder-layout-contrast--control-icon"></i>
            </wayfinder-button>
            <wayfinder-button
              :aria-label-value="$l10n('zoom up')"
              :title="$l10n('zoom up')"
              class="wayfinder-layout-contrast--map-control"
              color="black"
              corners="circle"
              modificator="only-text"
              @click.native="_zoomOut"
            >
              <i class="fa-regular fa-magnifying-glass-minus wayfinder-layout-contrast--control-icon"></i>
            </wayfinder-button>
          </wayfinder-button-group>
        </div>
      </div>

      <div
        ref="mainContent"
        class="wayfinder-layout-contrast--main-content"
        :style="{
          '--main-content': mainContentHeight
        }"
      >

        <div ref="keyboard" v-if="!isDesktop && $accessibility.enableSearch" class="wayfinder-layout-contrast--sidebar-keyboard">
          <wayfinder-button
            :aria-label-value="$l10n('toggle keyboard position')"
            :tabindex-value="keyboardOpened ? 0 : -1"
            :title="$l10n('toggle keyboard position')"
            class="wayfinder-layout-contrast--keyboard-handle"
            color="black"
            corners="circle"
            data-focus-frame-follow="500"
            @click.native="_toggleKeyboardPosition"
          >
            <i class="fa-light fa-angle-down"></i>
          </wayfinder-button>
          <wayfinder-fullsize-keyboard
            :gap="9"
            :layout="keyboardMap[selectedLanguage]"
            class="wayfinder--search-keyboard"
            @key-click="_handleKeyboard"
          />
        </div>

        <div :class="['wayfinder-layout-contrast--sidebar-content', {
          'wayfinder-layout-contrast--sidebar-content_close': !sidebarOpened
        }]"
        >
          <div ref="settings" class="wayfinder-layout-contrast--view-settings">
            <wayfinder-button
              v-if="selectedLegend.length >= 1"
              class="wayfinder-layout-contrast--come-back"
              color="black"
              @click.native="back"
              modificator="only-text"
              :aria-label-value="$l10n('Return to start')"
              :title="$l10n('Return to start')"
            >
              <i
                :class="['fa-regular', 'fa-arrow-left', 'wayfinder-layout-contrast--come-back-icon']"
              ></i>
              {{ $l10n('Return to start') }}
            </wayfinder-button>

            <div class="wayfinder-layout-contrast--information-language-wrapper">
              <wayfinder-button
                class="wayfinder-layout-contrast--toggle-list-button"
                color="black"
                @click.native="openInfo"
                modificator="only-text"
                :aria-label-value="isInfoVisible ? $l10n('Hide instruction') : $l10n('Show instructions')"
                :title="isInfoVisible ? $l10n('Hide instruction') : $l10n('Show instructions')"
              >
                <i :class="['fa-light', 'fa-eye', 'wayfinder-layout-contrast--toggle-list-button-icon', {
                  'fa-eye': isInfoVisible,
                  'fa-eye-slash': !isInfoVisible
                }]"></i>
                {{ isInfoVisible ? $l10n("Hide instruction") : $l10n("Show instructions") }}
              </wayfinder-button>

              <wayfinder-button-group
                class="wayfinder-layout-contrast--language-controls"
                gap="small"
              >
                <wayfinder-button
                  v-for="option in availableLocales"
                  :key="`${ option.label }-${ option.value }`"
                  :active="selectedLanguage === option.value"
                  :class="['wayfinder-layout-contrast--language-control', {
                    'wayfinder-layout-contrast--language-control_small': useShortLocales
                  }]"
                  color="black"
                  size="small"
                  :aria-label-value="$l10n('language name')"
                  :title="$l10n('language name')"
                  @click.native="_selectLocale(option.value)"
                >
                  {{ option.text }}
                </wayfinder-button>
              </wayfinder-button-group>
            </div>
            <wayfinder-select
              :value="selectedLanguage"
              :options="availableLocales"
              :placement="isMobile ? 'bottom-left' : 'top-left'"
              @change="(event) => _selectLocale(event)"
              class="wayfinder-layout-contrast--mobile-language-controls"
            />
          </div>

          <div class="wayfinder-layout-contrast--fake-border"></div>
          <div
            ref="userActionArea"
            :class="['wayfinder-layout-contrast--user-action-area', {
              'wayfinder-layout-contrast--user-action-area_with-separator': !isScrollAvalialable,
              'wayfinder-layout-contrast--user-action-area_with-search': $accessibility.enableSearch
            }]"
          >
            <div v-if="$accessibility.enableSearch" ref="input" class="wayfinder-layout-contrast--input-wrapper">
              <wayfinder-input
                ref="searchInput"
                :value="legendFilter"
                class="wayfinder-layout-contrast--search-input"
                :placeholder="$l10n('Fantasy, Novels, Weather')"
                force-focus="wayfinder-layout-contrast--main-content"
                @input="_setLegendFilter"
                @blur="_hideKeyboard"
                @focus="_showKeyboard"
                :aria-label-value="$l10n('search input')"
                :title="$l10n('search input')"
              />
            </div>


            <p
              v-if="legendGroup.filter(Boolean).length === 0"
              class="wayfinder-layout-contrast--no-result"
              :style="{ 'height': scrollableContentHeight }"
            >
              {{ $l10n("No results") }}
            </p>
            <template v-else>
              <span class="wayfinder-layout-contrast--here-marker">
                <span
                  v-if="hereMarkerId"
                  @click="_handleHereElement"
                >
                  {{ $l10n("You are here") }}
                  <i class="wayfinder-layout-contrast--here-icon fa-light fa-crosshairs"></i>
                </span>
              </span>


              <div class="wayfinder-layout-contrast--contrast-buttons">
                <scrollable
                  class="wayfinder-layout-contrast--list-scrollable wayfinder-layout-contrast--left-side"
                  :maxHeight="scrollableContentHeight"
                  @scroll-avaiable="isScrollAvalialable = $event"
                  :style="{ '--markers-height': scrollableContentHeight }"
                >
                  <div class="wayfinder-layout-contrast--headings">
                    <ul class="wayfinder-layout-contrast--legend-list">
                      <template v-for="(group, groupIndex) in legendGroup">
                        <li
                          v-for="(legendItem, legendItemIndex) in group"
                          class="wayfinder-layout-contrast--legend-item"
                          :key="legendItem.id"
                        >
                          <wayfinder-button
                            :class="[
                              'wayfinder-layout-contrast--button wayfinder-layout-contrast--button-marker-heading',
                              { 'wayfinder-layout-contrast--head': group.length - 1 === legendItemIndex && legendGroup.length - 1 !== groupIndex }
                            ]"
                            :active="isActiveMarker(legendItem)"
                            @click.native="_showChildElements(legendItem, { isRootElement: true })"
                            :title="$withLocale(legendItem.label) || legendItem._label"
                            :aria-label-value="$withLocale(legendItem.label) || legendItem._label"
                          >
                            {{ legendItem._label || $withLocale(legendItem.label) }}
                          </wayfinder-button>
                        </li>
                      </template>
                    </ul>
                  </div>
                </scrollable>
              </div>

              <div
                :class="['wayfinder-layout-contrast--markers wayfinder-layout-contrast--list-scrollable', {
                  'wayfinder-layout-contrast--markers_legend': selectedLegend.length !== 0
                }]"
                :style="{ '--markers-height': scrollableContentHeight }"
              >
                <scrollable
                  v-if="displayHint"
                  class="wayfinder-layout-contrast--list-scrollable"
                  :maxHeight="scrollableContentHeight"
                >
                  <div class="wayfinder-layout-contrast--hint">
                    <h3
                      class="wayfinder-layout-contrast--hint-title"
                      v-html="$l10n('Select from menu <br/> to view the data on the map')"
                    ></h3>

                    <p
                      class="wayfinder-layout-contrast--hint-subtitle"
                      v-html="$l10n('Below the services you can find e.g. WCt, lifts, <br/> printers and reserved spaces')"
                    ></p>
                  </div>
                </scrollable>

                <transition-group name="slide" tag="div">
                  <div
                    v-for="legend in selectedLegend"
                    :key="legend._parent.id"
                    class="wayfinder-layout-contrast--selected-legend"
                  >
                    <div v-if="isDescriptionElement(legend._parent)">
                      <p
                        v-if="selectedLegend.length !== 1 && selectedLegend[selectedLegend.length - 1]._parent._withLabel !== false"
                        :class="[
                          'wayfinder-layout-contrast--selected-legend-label',
                          'wayfinder-layout-contrast--selected-legend-label_no-padding'
                        ]"
                      >
                        {{ $withLocale(selectedLegend[selectedLegend.length - 1]._parent.label) }}
                      </p>
                      <div class="wayfinder-layout-contrast--information">
                        <scrollable
                          v-if="legend._parent._selectedPoints !== 0"
                          :class="[
                            'wayfinder-layout-contrast--list-scrollable',
                            {
                              'wayfinder-layout-contrast--list-scrollable_category-list': selectedLegend.length !== 1
                                && selectedLegend[selectedLegend.length - 1]._parent._withLabel !== false
                            }
                          ]"
                          :maxHeight="scrollableContentHeight"
                        >
                          <h3
                            class="wayfinder-layout-contrast--description"
                            v-html="$withLocale(legend._parent.description)"
                          ></h3>

                          <div
                            v-if="legend._parent.description"
                            class="wayfinder-layout-contrast--selected-legend-scrollable wayfinder-layout-contrast--related-markers"
                          >
                            <wayfinder-button
                              v-for="point in legend._parent._selectedPoints"
                              :key="point.id"
                              :class="[
                                'wayfinder-layout-contrast--button',
                                'wayfinder-layout-contrast--selected-legend-item'
                              ]"
                              :active="isActiveMarker(point)"
                              @click.native="selectElement(point)"
                              :aria-label-value="$withLocale(point.label)"
                              :title="$withLocale(point.label)"
                            >
                              {{ $withLocale(point.label) }}
                            </wayfinder-button>
                          </div>
                        </scrollable>
                      </div>
                    </div>

                    <div v-else>
                      <div v-if="selectedLegend[selectedLegend.length - 1]._parent._withLabel !== false" class="wayfinder-layout-contrast--head-line">
                        <p class="wayfinder-layout-contrast--selected-legend-label">
                          {{ $withLocale(selectedLegend[selectedLegend.length - 1]._parent.label) }}
                        </p>
                        <wayfinder-button
                          class="wayfinder-layout-contrast--button wayfinder-layout-contrast--description-button"
                          v-if="legend._parent.description"
                          @click.native="openDescription(legend._parent)"
                          :aria-label-value="$l10n('open description')"
                          :title="$l10n('open description')"
                        >
                          <i class="fa-light fa-circle-info wayfinder-layout-contrast--description-icon"></i>
                        </wayfinder-button>
                      </div>

                      <scrollable
                        v-if="selectedLegend.length !== 0"
                        :class="[
                          'wayfinder-layout-contrast--list-scrollable',
                          {
                            'wayfinder-layout-contrast--list-scrollable_category-list':
                              selectedLegend[selectedLegend.length - 1]._parent._withLabel !== false
                          }
                        ]"
                        :max-height="scrollableContentHeight"
                      >
                        <div class="wayfinder-layout-contrast--selected-legend-scrollable">
                          <wayfinder-button
                            v-for="legendElement in legend"
                            :key="legendElement.id"
                            :active="isActiveMarker(legendElement)"
                            class="wayfinder-layout-contrast--selected-legend-item wayfinder-layout-contrast--button"
                            @click.native="_showChildElements(legendElement)"
                            :aria-label-value="$withLocale(legendElement.label)"
                            :title="$withLocale(legendElement.label)"
                          >
                            {{ $withLocale(legendElement.label) }}
                          </wayfinder-button>
                        </div>
                      </scrollable>
                    </div>
                  </div>
                </transition-group>
              </div>
            </template>
            <div v-if="legendGroup.length !== 0" class="wayfinder-layout-contrast--mobile-action-area">
              <scrollable
                ref="scrollable"
                class=" wayfinder-layout-contrast--list-scrollable wayfinder-layout--sidebar-scrollable"
                maxHeight="100%"
              >
                <wayfinder-legend @open-description="(element) => openDescription(element)"/>
              </scrollable>
            </div>
          </div>
        </div>
      </div>

      <blank-modal
        ref="informationModal"
        class="wayfinder-layout-contrast--modal-info"
        @before-close="isInfoVisible = false"
      >
        <div class="wayfinder-modal--content">
          <h1 ref="informationTitleRef" class="wayfinder-layout-contrast--modal-title">
            {{ informationTitle }}
          </h1>
          <scrollable
            class="wayfinder-layout-contrast--modal-text"
            maxHeight="100%"
            smoothEdgeColor="#cccccc"
          >
            <div class="ql-snow">
              <div class="ql-editor" v-html="information"></div>
            </div>
          </scrollable>

          <wayfinder-button
            class="wayfinder-layout-contrast--close-button wayfinder-modal--close-button wayfinder-modal--close-button_top-right"
            color="white"
            corners="square"
            @click.native="() => $refs.informationModal.hide()"
            :aria-label-value="$l10n('Close')"
            :title="$l10n('Close')"
          >
            <span>{{ $l10n("Close") }}</span>
          </wayfinder-button>

          <wayfinder-button
            class="wayfinder-layout-contrast--close-button wayfinder-modal--close-button wayfinder-modal--close-button_bottom-right"
            color="white"
            corners="square"
            @click.native="() => $refs.informationModal.hide()"
            :aria-label-value="$l10n('Close')"
            :title="$l10n('Close')"
          >
            <span>{{ $l10n("Close") }}</span>
          </wayfinder-button>
        </div>
      </blank-modal>

      <blank-modal
        ref="descriptionModal"
        class="wayfinder-layout-contrast--modal-info"
        @closed="descriptionElement = null"
      >
        <div v-if="descriptionElement" class="wayfinder-modal--content">
          <h1 ref="informationTitleRef" class="wayfinder-layout-contrast--modal-title">
            {{ descriptionElement.label[selectedLanguage] }}
          </h1>
          <scrollable
            class="wayfinder-layout-contrast--modal-text"
            maxHeight="100%"
            smoothEdgeColor="#cccccc"
          >
            <div class="ql-snow">
              <div class="ql-editor" v-html="descriptionElement.description[selectedLanguage]"></div>
            </div>

            <wayfinder-button
              v-for="point in descriptionElement._selectedPoints"
              :key="point.id"
              :class="[
                'wayfinder-layout-contrast--button',
                'wayfinder-layout-contrast--selected-legend-item'
              ]"
              :active="isActiveMarker(point)"
              @click.native="selectElement(point)"
              :aria-label-value="$withLocale(point.label)"
              :title="$withLocale(point.label)"
            >
              {{ $withLocale(point.label) }}
            </wayfinder-button>
          </scrollable>

          <wayfinder-button
            class="wayfinder-layout-contrast--close-button wayfinder-modal--close-button wayfinder-modal--close-button_top-right"
            color="white"
            corners="square"
            @click.native="() => $refs.descriptionModal.hide()"
            :aria-label-value="$l10n('Close')"
            :title="$l10n('Close')"
          >
            <span>{{ $l10n("Close") }}</span>
          </wayfinder-button>

          <wayfinder-button
            class="wayfinder-layout-contrast--close-button wayfinder-modal--close-button wayfinder-modal--close-button_bottom-right"
            color="white"
            corners="square"
            @click.native="() => $refs.descriptionModal.hide()"
            :aria-label-value="$l10n('Close')"
            :title="$l10n('Close')"
          >
            <span>{{ $l10n("Close") }}</span>
          </wayfinder-button>
        </div>
      </blank-modal>
    </div>
  </div>
</template>

<style src="./layout-content-contrast.less" lang="less"></style>

<script>
  import { mapState, mapActions } from "vuex";
  import lodash from "lodash";
  import { getSearch } from "../../../lib/utils/url-query.js";
  import WayfinderSelect from "../../select/select.vue";
  import WayfinderMapList from "../../map/map-list.vue";
  import LanguageSeelct from "../../language-select/language-select.vue";
  import Scrollable from "../../scrollable/scrollable.vue";
  import BlankModal from "../../modal/blank.vue";
  import WayfinderLegend from "../../legend/legend.vue";
  import WayfinderInput from "../../input/input.vue";
  import WayfinderFullsizeKeyboard from "../../keyboard/fullsize.vue";

  const urlOptions = getSearch();
  export default {
    name: "layout-content-contrast",
    mixins: [WayfinderMapList, LanguageSeelct],
    watch: {
      keyboardOpened() {
        this._handleShowingKeyboard();
      },
      keyboardPosition() {
        this._setScrollableAreaHeight();
      }
    },
    props: {
      currentLayout: String
    },
    data() {
      return {
        isDesktop: "desktop" in urlOptions,
        isInfoVisible: false,
        isScrollAvalialable: false,
        selectedLegend: [],
        descriptionElement: null,
        mainContentHeight: null,
        scrollableContentHeight: null,
        orientation: this.$easyscreenCanvasOrientation.orientation
      };
    },
    methods: {
      ...mapActions({
        _selectLocale: "selectLocale",
        _selectLegendElements: "selectLegendElements",
        _toggleLegendElement: "toggleLegendElement",
        _setTheme: "setTheme",
        _hideSidebar: "hideSidebar",
        _toggleSidebar: "toggleSidebar",
        _showSidebar: "showSidebar",
        _changeScale: "changeScale",
        _resetScale: "resetScale",
        _hideKeyboard: "hideKeyboard",
        _showKeyboard: "showKeyboard",
        _toggleKeyboardPosition: "toggleKeyboardPosition"
      }),
      _showChildElements(element, options) {
        options = {
          isRootElement: false,
          autoClose: true,
          showOnMap: true,
          ...(options || {})
        };

        function recursiveMarkersBuilder(legend, selectedMarkers, _found) {
          const isRootCall = _found == null;
          _found = _found || [];

          legend.forEach( marker => {
            if (selectedMarkers.includes(marker.id)) {
              _found.push( marker );
            }

            if (marker.sub && marker.sub !== 0) {
              recursiveMarkersBuilder(marker.sub, selectedMarkers, _found);
            }
          } );

          if (isRootCall) {
            _found.sort( (a, b) => selectedMarkers.indexOf(a.id) - selectedMarkers.indexOf(b.id) );
          }
          return _found;
        }

        if (options.isRootElement)
          this.selectedLegend = [];

        let elementsToSelect;
        if (element.selectedPoints && element.selectedPoints !== 0) {
          element._selectedPoints = recursiveMarkersBuilder(this.selectedMap.legend, element.selectedPoints);
          elementsToSelect = element;
        }

        if (element.sub && element.sub.length !== 0 || Object.keys(element.description || {}).length !== 0) {
          element.sub._parent = element;
          elementsToSelect = element.sub._parent;
          this.selectedLegend = this.selectedLegend.concat([element.sub]);
        } else {
          elementsToSelect = element;
        }

        if (element._getCustomSelectedElements) {
          elementsToSelect = element._getCustomSelectedElements();
        }

        if (options.showOnMap && elementsToSelect) {
          this._selectLegendElements({ elements: elementsToSelect, autoClose: options.autoClose });
        }
      },
      isActiveMarker(marker) {
        const isServiceButton = marker.class != null;
        const isServiceButtonGroupSelected = this.selectedLegendElements.some((_marker) => _marker.id === "service-buttons");

        if (isServiceButton && isServiceButtonGroupSelected)
          return false;

        const inLegendStack = this.selectedLegend.find(_marker => {
          return _marker._parent.id === marker.id;
        });

        if (inLegendStack)
          return true;

        /* Do not highlight the root level marker when it's selected from the description (related markers). */
        const isRootMarker = this.$store.getters.selectedMap.legend.includes(marker);
        if (isRootMarker && this.selectedLegend.length > 0)
          return false;

        return this.$store.getters.selectedLegendElements.some(_marker => _marker.id === marker.id);
      },
      _recursiveFindLastActiveElement(legend, _path){
        _path = _path || [];
        legend.some(legendItem => {
          if (legendItem._isActive) {

            if (legendItem.sub && legendItem.sub.length !== 0){
              legendItem.sub._parent = legendItem;
              _path.push(legendItem.sub);
              this._recursiveFindLastActiveElement(legendItem.sub, _path);
            }
          }
          return legendItem._isActive;
        });
        return _path;
      },
      isDescriptionElement(element) {
        return !element.sub || element.sub.length === 0;
      },
      selectMap(value) {
        if (this.selectedLegend.some(legendArr => legendArr._parent.id === "service-buttons") === false)
          this.selectedLegend = [];
        this.__selectMap(value);
      },
      back() {
        this.selectedLegend = this.selectedLegend.slice(0, this.selectedLegend.length - 1);

        if (this.selectedLegend.length !== 0) {
          this._selectLegendElements({ elements: this.selectedLegend[this.selectedLegend.length - 1]._parent });
        }
      },
      openInfo() {
        this.isInfoVisible = true;

        this.$refs.informationModal.show();
      },

      openDescription(descriptionElement) {
        this.descriptionElement = descriptionElement;

        this.$refs.descriptionModal.show();
      },
      selectElement(element) {
        this.$refs.descriptionModal.hide();
        this._selectLegendElements({ elements: element });
        this._hideSidebar();
      },
      _handleHereElement() {
        this._selectLegendElements({ elements: { id: this.hereMarkerId } });
        this._hideSidebar();
      },
      _handleKeyboard(key) {
        if (key === "enter") {
          return this._hideKeyboard();
        }

        this.$refs.searchInput.focus();
        WayfinderFullsizeKeyboard.defaultKeyboardHandler({
          onUpdated: this.$nextTick,
          value: {
            set: (value) => {
              this._setLegendFilter(value);
            },
            get: () => this.legendFilter
          },
          cursor: {
            set: (cursorIndex) => this.$refs.searchInput.setCursor(cursorIndex),
            get: () => this.$refs.searchInput.getCursor()
          }
        }, key);
      },
      _setLegendFilter(value) {
        this.$store.dispatch("setLegendFilter", value);

        if (this.legendGroup[0] && this.legendGroup[0][0]) {
          this._showChildElements(this.legendGroup[0][0], { isRootElement: true, autoClose: this.isDesktop, showOnMap: false });
        } else {
          this._showChildElements([], { isRootElement: true, autoClose: this.isDesktop, showOnMap: false });
        }
      },
      _setScrollableAreaHeight() {
        if (this.isMobile)
          return;

        let headerSizes = getComputedStyle(this.$refs.header);
        let settings = getComputedStyle(this.$refs.settings);
        let userActionArea = getComputedStyle(this.$refs.userActionArea);
        let mainContent = getComputedStyle(this.$refs.mainContent);
        let floatingBlock = getComputedStyle(this.$refs.floatingBlock);
        let contrastWrapper = getComputedStyle(this.$refs.contrastWrapper);
        let keyboard = { height: 0 };
        let headerButtonsHeight = this.currentLayout === "custom" ? 100 : 0;

        if (this.$refs.keyboard)
          keyboard = getComputedStyle(this.$refs.keyboard);


        if (this.keyboardOpened === false) {
          this.mainContentHeight = parseInt(contrastWrapper.height, 10)
            - parseInt(contrastWrapper.paddingTop, 10)
            - parseInt(contrastWrapper.paddingBottom, 10)
            - parseInt(mainContent.paddingTop, 10)
            - parseInt(mainContent.paddingBottom, 10)
            - parseInt(headerSizes.height, 10)
            - parseInt(headerSizes.marginBottom, 10)
            - parseInt(settings.marginTop, 10)
            - headerButtonsHeight
            + "px";

          // 40px - it is the height of the lower scrollbar circle
          this.scrollableContentHeight = parseInt(this.mainContentHeight, 10)
            - parseInt(settings.height, 10)
            - parseInt(settings.marginTop, 10)
            - parseInt(settings.marginBottom, 10)
            - parseInt(userActionArea.paddingTop, 10)
            - parseInt(userActionArea.paddingBottom, 10)
            - 40
            + "px";

          if (this.currentLayout === "custom") {
            // 40px - it is the height of the lower scrollbar circle
            this.scrollableContentHeight = parseInt(this.mainContentHeight, 10)
              - parseInt(contrastWrapper.paddingBottom, 10)
              - parseInt(settings.height, 10)
              - parseInt(settings.marginTop, 10)
              - parseInt(settings.marginBottom, 10)
              - parseInt(userActionArea.paddingTop, 10)
              - parseInt(userActionArea.paddingBottom, 10)
              - 40
              + "px";
          }

          if (this.orientation === "portrait-orientation") {
            this.mainContentHeight = parseInt(contrastWrapper.height, 10)
              - parseInt(floatingBlock.height, 10)
              - parseInt(floatingBlock.paddingBottom, 10)
              - parseInt(settings.height, 10)
              - parseInt(settings.marginTop, 10)
              - parseInt(settings.marginBottom, 10)
              + "px";

            this.scrollableContentHeight = parseInt(this.mainContentHeight, 10)
              - parseInt(contrastWrapper.paddingTop, 10)
              - parseInt(contrastWrapper.paddingBottom, 10)
              - parseInt(settings.height, 10)
              - parseInt(settings.marginTop, 10)
              - parseInt(settings.marginBottom, 10)
              - parseInt(userActionArea.paddingTop, 10)
              - parseInt(userActionArea.paddingBottom, 10)
              + "px";

            if (this.currentLayout === "custom") {
              this.mainContentHeight = parseInt(contrastWrapper.height, 10)
                - parseInt(floatingBlock.height, 10)
                - parseInt(floatingBlock.paddingBottom, 10)
                - parseInt(settings.height, 10)
                - parseInt(settings.marginTop, 10)
                - parseInt(settings.marginBottom, 10)
                - parseInt(userActionArea.paddingTop, 10)
                - parseInt(userActionArea.paddingBottom, 10)
                + "px";

              this.scrollableContentHeight = parseInt(this.mainContentHeight, 10)
                - parseInt(contrastWrapper.paddingTop, 10)
                - parseInt(contrastWrapper.paddingBottom, 10)
                - parseInt(settings.height, 10)
                - parseInt(settings.marginTop, 10)
                - parseInt(settings.marginBottom, 10)
                - parseInt(userActionArea.paddingTop, 10)
                - parseInt(userActionArea.paddingBottom, 10)
                + "px";
            }
          }
        } else if (this.keyboardPosition === "at-bottom") {
          if (this.orientation === "portrait-orientation") {
            // 40px - it is the height of the lower scrollbar circle
            this.mainContentHeight = parseInt(contrastWrapper.height, 10)
              - parseInt(floatingBlock.height, 10)
              - parseInt(floatingBlock.paddingBottom, 10)
              - parseInt(settings.height, 10)
              - parseInt(settings.marginTop, 10)
              - parseInt(settings.marginBottom, 10)
              + 40
              + "px";
          } else {
            this.mainContentHeight = parseInt(contrastWrapper.height, 10)
              - parseInt(mainContent.paddingTop, 10)
              - parseInt(mainContent.paddingBottom, 10)
              - parseInt(keyboard.height, 10)
              + "px";

            this.scrollableContentHeight = parseInt(this.mainContentHeight, 10)
              - parseInt(userActionArea.paddingBottom, 10)
              - parseInt(userActionArea.paddingTop, 10)
              - parseInt(settings.height, 10)
              - parseInt(settings.marginTop, 10)
              - parseInt(settings.marginBottom, 10)
              - parseInt(contrastWrapper.paddingTop, 10)
              - parseInt(contrastWrapper.paddingBottom, 10)
              + "px";
          }
        } else {
          this.mainContentHeight = parseInt(contrastWrapper.height, 10)
            - parseInt(mainContent.paddingTop, 10)
            - parseInt(mainContent.paddingBottom, 10)
            - parseInt(keyboard.height, 10)
            + "px";

          this.scrollableContentHeight = parseInt(this.mainContentHeight, 10)
            - parseInt(userActionArea.paddingBottom, 10)
            - parseInt(userActionArea.paddingTop, 10)
            - parseInt(settings.height, 10)
            - parseInt(settings.marginTop, 10)
            - parseInt(settings.marginBottom, 10)
            - parseInt(contrastWrapper.paddingTop, 10)
            - parseInt(contrastWrapper.paddingBottom, 10)
            + "px";

          if (this.orientation === "portrait-orientation") {
            this.mainContentHeight = parseInt(contrastWrapper.height, 10)
              - parseInt(floatingBlock.height, 10)
              - parseInt(floatingBlock.paddingBottom, 10)
              - parseInt(settings.height, 10)
              - parseInt(settings.marginTop, 10)
              - parseInt(settings.marginBottom, 10)
              + "px";

            this.scrollableContentHeight = parseInt(this.mainContentHeight, 10)
              - parseInt(contrastWrapper.paddingTop, 10)
              - parseInt(contrastWrapper.paddingBottom, 10)
              - parseInt(settings.height, 10)
              - parseInt(settings.marginTop, 10)
              - parseInt(settings.marginBottom, 10)
              - parseInt(userActionArea.paddingTop, 10)
              - parseInt(userActionArea.paddingBottom, 10)
              + "px";

            if (this.currentLayout === "custom") {
              this.mainContentHeight = parseInt(contrastWrapper.height, 10)
                - parseInt(floatingBlock.height, 10)
                - parseInt(floatingBlock.paddingBottom, 10)
                - parseInt(settings.height, 10)
                - parseInt(settings.marginTop, 10)
                - parseInt(settings.marginBottom, 10)
                - parseInt(userActionArea.paddingTop, 10)
                - parseInt(userActionArea.paddingBottom, 10)
                + "px";

              this.scrollableContentHeight = parseInt(this.mainContentHeight, 10)
                - parseInt(contrastWrapper.paddingTop, 10)
                - parseInt(contrastWrapper.paddingBottom, 10)
                - parseInt(settings.height, 10)
                - parseInt(settings.marginTop, 10)
                - parseInt(settings.marginBottom, 10)
                - parseInt(userActionArea.paddingTop, 10)
                - parseInt(userActionArea.paddingBottom, 10)
                + "px";
            }
          }
        }
      },
      _handleShowingKeyboard() {
        if (this.animationTimerId)
          clearTimeout(this.animationTimerId);

        this.$refs.mainContent.classList.add("wayfinder-layout-contrast--main-content_animated-scroll-height");

        this.animationTimerId = setTimeout(
          () => this.$refs.mainContent.classList.remove("wayfinder-layout-contrast--main-content_animated-scroll-height"),
          500
        );
        this._setScrollableAreaHeight();

        if (this.keyboardOpened) {
          clearTimeout(this.zIndexKeyboard);

          this.zIndexKeyboard = setTimeout(() => this.$refs.keyboard.classList.add("wayfinder-layout-contrast--sidebar-keyboard_hight-z-index"), 500);
        } else {
          this.$refs.keyboard.classList.remove("wayfinder-layout-contrast--sidebar-keyboard_hight-z-index");
        }
      }
    },
    computed: {
      ...mapState({
        theme: state => state.theme,
        localesNames: state => state.view.localesNames,
        locales: state => state.view.locales,
        sidebarOpened: state => state.sidebarOpened,
        keyboardOpened: state => state.keyboardOpened,
        keyboardPosition: state => state.keyboardPosition,
        keyboardMap: state => state.view.keyboardMap,
        isMobile: state => state.isMobile
      }),
      useShortLocales() {
        return this.locales.length > 3;
      },
      serviceButtons() {
        let serviceButtons = {};
        this.$store.getters.filteredMaps.forEach(map => {
          map.icons.forEach(serviceButton => {
            serviceButtons[serviceButton.id] = serviceButton;
          });
        });

        return Object.values(serviceButtons);
      },
      isLightMode() {
        return this.theme === "contrast-light";
      },
      availableLocales() {
        return this.locales.map((localeCode) => {
          return {
            value: localeCode,
            text: this.useShortLocales ? localeCode : (this.localesNames[localeCode] || localeCode)
          };
        });
      },
      information() {
        return lodash.get(this.view, `information[${ this.selectedLanguage }]`);
      },
      informationTitle() {
        return lodash.get(this.view, `informationTitle[${ this.selectedLanguage }]`);
      },
      legend() {
        return this.view;
      },
      selectedLanguage: {
        get() {
          return this.$store.getters.selectedLocale;
        }
      },
      view() {
        return this.$store.getters.filteredView;
      },
      logoImage() {
        return this.$store.getters.filteredView.logoImages[this.selectedLanguage] || Object.values(this.view.logoImages)[0];
      },
      branchOptions() {
        return this.view.maps.map( (map) => {
          return {
            value: map.id,
            text: this.$withLocale(map.label)
          };
        });
      },
      selectedMap() {
        return this.$store.getters.selectedMap;
      },
      selectedLegendElements() {
        return this.$store.getters.selectedLegendElements;
      },
      hereMarkerId() {
        return this.$store.getters.hereMarkerId;
      },
      displayHint() {
        return this.selectedLegend.length === 0;
      },
      legendGroup() {
        let _legendGroup = [];
        if (this.serviceButtons.length !== 0) {
          _legendGroup.push([{
            id: "service-buttons",
            _label: this.$l10n("Services"),
            sub: this.serviceButtons,
            _isActive: this.serviceButtons.some(serviceButton => serviceButton._isActive),
            _withLabel: false,
            _getCustomSelectedElements: () => {
              return [{ id: "service-buttons" }].concat(this.serviceButtons);
            }
          }]);
        }

        if (this.legendFilter.length !== 0) {
          let searchResult = [];
          this.$store.getters.filteredMaps.forEach(map => {
            if (map.legend.length !== 0) {
              searchResult = searchResult.concat(map.legend);
            }
          });

          if (searchResult.length !== 0) {
            return _legendGroup.concat([searchResult]);
          }

          return _legendGroup;
        }

        return _legendGroup.concat([this.filteredMap.legend]);
      },
      legendFilter() {
        return this.$store.state.legendFilter;
      },
      filteredMap() {
        return this.$store.getters.filteredMaps.filter(map => map.id === this.selectedMap.id)[0];
      }
    },
    created() {
      this._setScrollableAreaHeight = this._setScrollableAreaHeight.bind(this);
    },
    mounted() {
      this._setScrollableAreaHeight();
      window.addEventListener("resize", this._setScrollableAreaHeight);

      this.legendGroup.some(group => {
        this.selectedLegend = this._recursiveFindLastActiveElement(group);
        return this.selectedLegend.length !== 0;
      });
    },
    beforeDestroy() {
      window.removeEventListener("resize", this._setScrollableAreaHeight);
    },
    components: {
      WayfinderSelect,
      Scrollable,
      BlankModal,
      WayfinderLegend,
      WayfinderFullsizeKeyboard,
      WayfinderInput
    },
    screenPending() {
      this.selectedLegend = [];
      this.branch = null;
      this._resetScale();
      clearTimeout(this.animationTimerId);
      clearTimeout(this.zIndexKeyboard);
    }
  };
</script>
